import * as icons from "./icons";

export const editMenulist = [
  { menuId: 0, caption: "Save", icon: icons["saveIcon"] },
  { menuId: 1, caption: "Cancel", icon: icons["cancelIcon"] },
];

export const providerFields = [
  { id: 0, name: "providerId", caption: "Provider Id", width: -1 },
  { id: 1, name: "providerName", caption: "Provider name", width: 0, align: "left", type: "string" },
];

export const lookupFields = [
  { id: 0, name: "lookupId", width: -1 },
  { id: 1, name: "lookupContent", width: 0 },
];

export const typeList = [
  { lookupId: 1, lookupContent: "MERCHANT" },
  { lookupId: 2, lookupContent: "BRONZE AGENT" },
  { lookupId: 3, lookupContent: "SILVER AGENT" },
  { lookupId: 4, lookupContent: "GOLD AGENT" },
  { lookupId: 5, lookupContent: "PLATINUM AGENT" },
  { lookupId: 6, lookupContent: "DIAMOND AGENT" },
];

export const statusList = [
  { lookupId: 1, lookupContent: "APPLIED" },
  { lookupId: 2, lookupContent: "DECLINED" },
  { lookupId: 3, lookupContent: "APPROVED" },
  { lookupId: 4, lookupContent: "ACTIVE" },
  { lookupId: 5, lookupContent: "INACTIVE" },
];

export const providerTopupOptionList = [
  { lookupId: 1, lookupContent: "ON CREDIT" },
  { lookupId: 2, lookupContent: "ELECTRONIC FUND TRANSFER" },
  { lookupId: 3, lookupContent: "COMMISSION" },
];

export const agentTopupOptionList = [
  { lookupId: 1, lookupContent: "ON CREDIT" },
  { lookupId: 2, lookupContent: "ELECTRONIC FUND TRANSFER" },
];

export const paymentOptionList = [
  { lookupId: 1, lookupContent: "PST MOBILE" },
  { lookupId: 2, lookupContent: "PST BET" },
];
