import { forwardRef, useImperativeHandle, useRef } from "react";

const ToggleButton = forwardRef(({ isReadOnly, value, setValue }, ref) => {
  const toggleButtonRef = useRef();

  function mounted() {
    return new Promise((res) => {
      setTimeout(res, 10);
    });
  }

  useImperativeHandle(ref, () => ({
    focus() {
      mounted().then(() => {
        toggleButtonRef.current.focus();
      });
    },
  }));

  const valueChange = () => {
    if (!isReadOnly && setValue) {
      if (value === null) setValue(true);
      else setValue(!value);
    }
  };

  const keyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
      valueChange();
    }
  };

  return (
    <div ref={toggleButtonRef} className="toggle-box" tabIndex={isReadOnly ? "" : "0"} onClick={valueChange} onKeyDown={keyDown}>
      <div className="toggle-button" style={{ background: value ? "var(--success)" : "var(--label)", float: value ? "right" : "left" }}></div>
    </div>
  );
});

export default ToggleButton;
