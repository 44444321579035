import { useEffect, useRef } from "react";

const Container = ({ mode, children }) => {
  const mainPanelRef = useRef();

  const handleTabKey = (e) => {
    const focusableElements = mainPanelRef.current.querySelectorAll('input, textarea, button, [tabindex="0"]');

    if (focusableElements && focusableElements.length > 0 && mode === "modal") {
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        return e.preventDefault();
      }

      if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        e.preventDefault();
      }
    }
  };

  const keyListenersMap = new Map([[9, handleTabKey]]);

  useEffect(() => {
    function keyListener(e) {
      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    }
    document.addEventListener("keydown", keyListener);

    return () => document.removeEventListener("keydown", keyListener);
  });

  return (
    <div ref={mainPanelRef} className={mode === "main" ? "normal-container" : mode === "modal" ? "modal-container" : "wrap-container"}>
      {children}
    </div>
  );
};

export default Container;
