import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useMaster, useMessage } from "../../context/MasterContext";

import { Container, Toolbar, WrapPanel, GroupBox, GroupElement, ToggleButton } from "../../controls";
import { AgentSearch, AgentTopup, AgentPayment, AgentApprove, AgentDecline, AgentActivate, AgentDeactivate, AgentChange, AgentEnableCredit, AgentDisableCredit, AgentUnblock } from "../../components";
import { formatCurrency, getType, getStatus } from "../../library/helper";

import * as icons from "../../library/icons";

const AgentPage = () => {
  const { user } = useMaster();
  const [menuList, setMenuList] = useState([]);
  const [agent, setAgent] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showTopup, setShowTopup] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [showDecline, setShowDecline] = useState(false);
  const [showActivate, setShowActivate] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [showChange, setShowChange] = useState(false);
  const [showEnableCredit, setShowEnableCredit] = useState(false);
  const [showDisableCredit, setShowDisableCredit] = useState(false);
  const [showUnblock, setShowUnblock] = useState(false);

  const navigate = useNavigate();

  const { messageBox } = useMessage();

  useEffect(() => {
    console.log(agent);

    let tempList = [];

    tempList.push({ menuId: 0, caption: "Search", icon: icons["searchIcon"] });

    if (agent) {
      switch (agent.statusId) {
        case 1:
          tempList.push({ menuId: 1, caption: "Approve", icon: icons["approveIcon"] });
          tempList.push({ menuId: 2, caption: "Decline", icon: icons["declineIcon"] });
          break;
        case 3:
          if (agent.typeId !== 1) tempList.push({ menuId: 3, caption: "Top-up", icon: icons["topupIcon"] });
          if (agent.typeId !== 1 && agent.typeId !== 2 && agent.typeId !== 4) tempList.push({ menuId: 7, caption: "Change type", icon: icons["changeTypeIcon"] });
          break;
        case 4:
          if (agent.typeId !== 1) tempList.push({ menuId: 3, caption: "Top-up", icon: icons["topupIcon"] });
          if (agent.allowCredit && agent.typeId !== 1) tempList.push({ menuId: 4, caption: "Credit payment", icon: icons["paymentIcon"] });
          if (!agent.allowCredit && agent.typeId !== 1) tempList.push({ menuId: 5, caption: "Enable credit", icon: icons["allowCreditIcon"] });
          if (agent.allowCredit && agent.typeId !== 1) tempList.push({ menuId: 6, caption: "Disable credit", icon: icons["disallowCreditIcon"] });
          if (agent.typeId !== 1 && agent.typeId !== 2 && agent.typeId !== 4) tempList.push({ menuId: 7, caption: "Change type", icon: icons["changeTypeIcon"] });
          tempList.push({ menuId: 8, caption: "Deactivate", icon: icons["deactivateIcon"] });
          break;
        case 5:
          if (agent.allowCredit && agent.typeId !== 1) tempList.push({ menuId: 4, caption: "Credit payment", icon: icons["paymentIcon"] });
          tempList.push({ menuId: 9, caption: "Activate", icon: icons["approveIcon"] });
          break;
        default:
          break;
      }

      if (agent.isBlocked) tempList.push({ menuId: 10, caption: "Unblock", icon: icons["unlockIcon"] });
    }

    tempList.push({ menuId: 99, caption: "Close", icon: icons["closeIcon"] });

    setMenuList(tempList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent]);

  const menuClick = (menuId) => {
    switch (menuId) {
      case 0:
        setAgent(null);
        setShowSearch(true);
        break;
      case 1:
        if (agent.agentId === user.agentId) {
          messageBox({ type: 2, message: "You are not allowed to process decision for own account" });
        } else {
          setShowApprove(true);
        }
        break;
      case 2:
        if (agent.agentId === user.agentId) {
          messageBox({ type: 2, message: "You are not allowed to process decision for own account" });
        } else {
          setShowDecline(true);
        }
        break;
      case 3:
        if (agent.agentId === user.agentId) {
          messageBox({ type: 2, message: "You are not allowed top up own account" });
        } else {
          setShowTopup(true);
        }
        break;
      case 4:
        if (agent.agentId === user.agentId) {
          messageBox({ type: 2, message: "You are not allowed to process payment for own account" });
        } else {
          setShowPayment(true);
        }
        break;
      case 5:
        if (agent.agentId === user.agentId) {
          messageBox({ type: 2, message: "You are not allowed to enable credit for own account" });
        } else {
          setShowEnableCredit(true);
        }
        break;
      case 6:
        if (agent.agentId === user.agentId) {
          messageBox({ type: 2, message: "You are not allowed to disable credit for own account" });
        } else {
          setShowDisableCredit(true);
        }
        break;
      case 7:
        if (agent.agentId === user.agentId) {
          messageBox({ type: 2, message: "You are not allowed to change type for own account" });
        } else {
          setShowChange(true);
        }
        break;
      case 8:
        if (agent.agentId === user.agentId) {
          messageBox({ type: 2, message: "You are not allowed to deactivate own account" });
        } else {
          setShowDeactivate(true);
        }
        break;
      case 9:
        if (agent.agentId === user.agentId) {
          messageBox({ type: 2, message: "You are not allowed to activate own account" });
        } else {
          setShowActivate(true);
        }
        break;
      case 10:
        if (agent.agentId === user.agentId) {
          messageBox({ type: 2, message: "You are not allowed to unblock own account" });
        } else {
          setShowUnblock(true);
        }
        break;
      default:
        navigate("/");
        break;
    }
  };

  return (
    <Container mode="main">
      <Toolbar itemSource={menuList} onClick={(menuId) => menuClick(menuId)} />
      {agent && (
        <WrapPanel>
          <GroupBox title="Personal detail">
            <GroupElement label="Agent name">
              <p>{agent.agentName}</p>
            </GroupElement>
            <GroupElement label="ID number">
              <p>{agent.nationalId}</p>
            </GroupElement>
            <GroupElement label="Mobile">
              <p>{agent.mobile}</p>
            </GroupElement>
          </GroupBox>
          <GroupBox title="ID or passport">
            <GroupElement label="Front side">
              <img src={process.env.REACT_APP_DOCS_URL + agent.cardFront} style={{ width: "100%" }} alt="Front side" />
            </GroupElement>
            <GroupElement label="Back side">
              <img src={process.env.REACT_APP_DOCS_URL + agent.cardBack} style={{ width: "100%" }} alt="Back side" />
            </GroupElement>
          </GroupBox>
          <GroupBox title="Agent detail">
            <GroupElement label="Type">
              <p>{getType(agent.typeId)}</p>
            </GroupElement>
            <GroupElement label="Signed on">
              <p>{agent.signedOn}</p>
            </GroupElement>
            {agent.decisionOn && (
              <GroupElement label="Decision processed on">
                <p>{agent.decisionOn}</p>
              </GroupElement>
            )}
            {agent.decisionBy && (
              <GroupElement label="Decision processed by">
                <p>{agent.decisionName}</p>
              </GroupElement>
            )}
            <GroupElement label="Status">
              <p style={{ color: agent.statusId === 2 || agent.statusId === 5 ? "var(--error)" : agent.statusId === 4 ? "var(--success)" : "var(--dark)" }}>{getStatus(agent.statusId)}</p>
            </GroupElement>
            <GroupElement label="Blocked">
              <ToggleButton isReadOnly={true} value={agent.isBlocked} />
            </GroupElement>
          </GroupBox>
          <GroupBox title="Wallet">
            <GroupElement label="Main wallet" align="right">
              <p>{`N$ ${formatCurrency(agent.mainWallet, 2)}`}</p>
            </GroupElement>
            <GroupElement label="Commission wallet" align="right">
              <p>{`N$ ${formatCurrency(agent.commissionWallet, 2)}`}</p>
            </GroupElement>
            <GroupElement label="Credit allowed" align="right">
              <ToggleButton isReadOnly={true} value={agent.allowCredit} />
            </GroupElement>
            <GroupElement label="Outstanding credit" align="right">
              <p style={{ color: "var(--error)" }}>{`N$ ${formatCurrency(agent.creditBalance, 2)}`}</p>
            </GroupElement>
          </GroupBox>
        </WrapPanel>
      )}
      {showSearch && <AgentSearch setAgent={setAgent} onHide={() => setShowSearch(false)} />}
      {showTopup && <AgentTopup agent={agent} setAgent={setAgent} onHide={() => setShowTopup(false)} />}
      {showPayment && <AgentPayment agent={agent} setAgent={setAgent} onHide={() => setShowPayment(false)} />}
      {showApprove && <AgentApprove agent={agent} setAgent={setAgent} onHide={() => setShowApprove(false)} />}
      {showDecline && <AgentDecline agent={agent} setAgent={setAgent} onHide={() => setShowDecline(false)} />}
      {showActivate && <AgentActivate agent={agent} setAgent={setAgent} onHide={() => setShowActivate(false)} />}
      {showDeactivate && <AgentDeactivate agent={agent} setAgent={setAgent} onHide={() => setShowDeactivate(false)} />}
      {showChange && <AgentChange agent={agent} setAgent={setAgent} onHide={() => setShowChange(false)} />}
      {showEnableCredit && <AgentEnableCredit agent={agent} setAgent={setAgent} onHide={() => setShowEnableCredit(false)} />}
      {showDisableCredit && <AgentDisableCredit agent={agent} setAgent={setAgent} onHide={() => setShowDisableCredit(false)} />}
      {showUnblock && <AgentUnblock agent={agent} setAgent={setAgent} onHide={() => setShowUnblock(false)} />}
    </Container>
  );
};

export default AgentPage;
