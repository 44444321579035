import { useEffect, useState } from "react";

import { useMaster, useMessage } from "../../../context/MasterContext";
import { Container, GroupBox, GroupElement } from "../../../controls";
import { formatCurrency } from "../../../library/helper";

import axios from "../../../library/service";

const ProviderBalance = ({ provider, onHide }) => {
  const { setIsBusy, setIsEdit, token } = useMaster();
  const [providerBalance, setProviderBalance] = useState(null);

  const { messageBox } = useMessage();

  useEffect(() => {
    setIsEdit(true);
    loadBalance();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadBalance = async () => {
    setIsBusy(true);

    const parameter = { providerId: provider.providerId };

    try {
      await axios
        .post("/agent/providerBalance", JSON.stringify(parameter), {
          headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        })
        .then((response) => {
          setIsBusy(false);

          let result = response.data;

          if (result.type !== 1) {
            messageBox({ type: result.type, message: result.message });
          } else {
            setProviderBalance(result.providerBalance);
          }
        });
    } catch (error) {
      setIsBusy(false);

      messageBox({ type: 99, message: error.message });
    }
  };

  const closeClick = () => {
    onHide();
    setIsEdit(false);
  };

  return (
    <div className="overlay" style={{ zIndex: 97 }}>
      <div className="login-form" style={{ boxShadow: "0 0 10px var(--blurry)", width: "350px" }}>
        <div className="login-title">
          <p>SERVICE PROVIDER BALANCE</p>
        </div>
        <Container mode="wrap">
          <hr className="separator-top" />
          <GroupBox>
            <GroupElement label="Provider name" align="right">
              <p style={{ fontSize: "16px", fontWeight: 700 }}>{provider.providerName}</p>
            </GroupElement>
            {providerBalance && (
              <GroupElement label="Available balance" align="right">
                <p>{`N$ ${formatCurrency(providerBalance.creditBalance, 2)}`}</p>
              </GroupElement>
            )}
            {providerBalance && (
              <GroupElement label="Accrued commission" align="right">
                <p>{`N$ ${formatCurrency(providerBalance.commissionBalance, 2)}`}</p>
              </GroupElement>
            )}
            {providerBalance && (
              <GroupElement label="Receivable" align="right">
                <p>{`N$ ${formatCurrency(providerBalance.receivableBalance, 2)}`}</p>
              </GroupElement>
            )}
            {providerBalance && (
              <GroupElement label="Payable" align="right">
                <p>{`N$ ${formatCurrency(providerBalance.payableBalance, 2)}`}</p>
              </GroupElement>
            )}
            {providerBalance && (
              <GroupElement label="Income" align="right">
                <p>{`N$ ${formatCurrency(providerBalance.incomeBalance, 2)}`}</p>
              </GroupElement>
            )}
            <hr className="separator-bottom" />
          </GroupBox>
          <div className="login-reset">
            <button onClick={closeClick}>Close</button>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ProviderBalance;
