import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useMaster, useMessage } from "../../../context/MasterContext";

import logo from "../../../assets/logo.png";
import * as icons from "../../../library/icons";

const Menu = ({ onHide }) => {
  const { socket, user, setUser } = useMaster();

  const menuRef = useRef();

  useEffect(() => {
    function detectOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onHide();
      }
    }

    document.addEventListener("mousedown", detectOutside);
    return () => {
      document.removeEventListener("mousedown", detectOutside);
    };
  });

  const { messageBox } = useMessage();

  const navigate = useNavigate();

  const menuClick = async (menuId) => {
    onHide();

    switch (menuId) {
      case 1:
        navigate("/");
        break;
      case 2:
        navigate("/provider");
        break;
      case 3:
        navigate("/agent");
        break;
      case 4:
        navigate("/gl");
        break;
      case 5:
        navigate("/report");
        break;
      default:
        await messageBox({ type: 98, message: "You will be logged out, do you want to continue?" }).then((confirmed) => {
          if (confirmed) {
            setUser(null);

            socket.emit("signOut");
            navigate("/login");
          }
        });
        break;
    }
  };

  return (
    <div ref={menuRef} className="menu">
      <div className="menu-logo" onClick={onHide}>
        <img src={logo} alt="" />
      </div>
      <div className="menu-content">
        <div className="menu-item" onClick={() => menuClick(1)}>
          <svg viewBox="0 0 24 24">
            <path d={icons.dashboardIcon}></path>
          </svg>
          <p>Dashboard</p>
        </div>
        {user && user.typeId === 6 && (
          <div className="menu-item" onClick={() => menuClick(2)}>
            <svg viewBox="0 0 24 24">
              <path d={icons.providerIcon}></path>
            </svg>
            <p>Service provider</p>
          </div>
        )}
        <div className="menu-item" onClick={() => menuClick(3)}>
          <svg viewBox="0 0 24 24">
            <path d={icons.agentIcon}></path>
          </svg>
          <p>Agent</p>
        </div>
        {user && user.typeId === 6 && (
          <div className="menu-item" onClick={() => menuClick(4)}>
            <svg viewBox="0 0 24 24">
              <path d={icons.folderOpenIcon}></path>
            </svg>
            <p>General ledger</p>
          </div>
        )}
        <div className="menu-item" onClick={() => menuClick(5)}>
          <svg viewBox="0 0 24 24">
            <path d={icons.reportIcon}></path>
          </svg>
          <p>Report</p>
        </div>
        <div className="menu-item" onClick={() => menuClick(99)}>
          <svg viewBox="0 0 24 24">
            <path d={icons.closeIcon}></path>
          </svg>
          <p>Log out</p>
        </div>
      </div>
    </div>
  );
};

export default Menu;
