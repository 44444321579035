import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useMaster, useMessage } from "../../../context/MasterContext";
import { Container, GroupBox, GroupElement, ComboBox, CurrencyBox, PasswordBox } from "../../../controls";
import { providerTopupOptionList, lookupFields } from "../../../library/static";
import { formatCurrency } from "../../../library/helper";

import axios from "../../../library/service";

const ProviderTopup = ({ provider, onHide }) => {
  const { socket, setUser, setIsBusy, setIsEdit, company, token } = useMaster();
  const [providerBalance, setProviderBalance] = useState(null);
  const [optionId, setOptionId] = useState(provider.optionId);
  const [amount, setAmount] = useState(null);
  const [pinCode, setPinCode] = useState("");
  const [attempt, setAttempt] = useState(0);

  const amountRef = useRef();
  const optionRef = useRef();
  const pinRef = useRef();

  const navigate = useNavigate();

  const { messageBox } = useMessage();

  useEffect(() => {
    setIsEdit(true);
    loadBalance();
    amountRef.current.focus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadBalance = async () => {
    setIsBusy(true);

    const parameter = { providerId: provider.providerId };

    try {
      await axios
        .post("/agent/providerBalance", JSON.stringify(parameter), {
          headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        })
        .then((response) => {
          setIsBusy(false);

          let result = response.data;

          if (result.type !== 1) {
            messageBox({ type: result.type, message: result.message });
          } else {
            setProviderBalance(result.providerBalance);
          }
        });
    } catch (error) {
      setIsBusy(false);

      messageBox({ type: 99, message: error.message });
    }
  };

  const topupClick = () => {
    if (amount === null || amount === 0) {
      messageBox({ type: 2, message: "Amount required" }).then(() => {
        amountRef.current.focus();
      });
    } else if (optionId === null) {
      messageBox({ type: 2, message: "Choose payment type" }).then(() => {
        optionRef.current.focus();
      });
    } else if (pinCode === "") {
      messageBox({ type: 2, message: "Enter your PIN code" }).then(() => {
        pinRef.current.focus();
      });
    } else if (pinCode.length !== 4) {
      messageBox({ type: 2, message: "Invalid PIN code, try again" }).then(() => {
        pinRef.current.focus();
      });
    } else if (!/^\d+$/.test(pinCode)) {
      messageBox({ type: 2, message: "Invalid PIN code, try again" }).then(() => {
        pinRef.current.focus();
      });
    } else {
      topup();
    }
  };

  const topup = async () => {
    setIsBusy(true);

    const parameter = {
      providerId: provider.providerId,
      amount,
      optionId,
      debitAccountId: provider.controlId,
      creditAccountId: optionId === 1 ? provider.payableId : optionId === 2 ? company.bankAccountId : provider.receivableId,
      pinCode,
      attempt,
    };

    try {
      await axios.post("/agent/topupProvider", JSON.stringify(parameter), { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then((response) => {
        setIsBusy(false);

        let result = response.data;

        if (result.type !== 1) {
          if (result.type === 96) {
            setAttempt(attempt + 1);
            messageBox({ type: result.type, message: `${result.message}\n\n${2 - attempt} ${2 - attempt > 1 ? "ATTEMPTS" : "ATTEMPT"} LEFT!!!` }).then(() => {
              pinRef.current.focus();
            });
          } else if (result.type === 97) {
            messageBox({ type: 99, message: result.message }).then(() => {
              setIsEdit(false);
              setUser(null);
              socket.emit("signOut");
              navigate("/");
            });
          } else {
            messageBox({ type: result.type, message: result.message }).then(() => {
              pinRef.current.focus();
            });
          }
        } else {
          messageBox({ type: result.type, message: `${provider.providerName} ${result.message}` }).then(() => {
            onHide();
            setIsEdit(false);
          });
        }
      });
    } catch (error) {
      setIsBusy(false);

      messageBox({ type: 99, message: error.message }).then(() => {
        pinRef.current.focus();
      });
    }
  };

  const cancelClick = () => {
    onHide();
    setIsEdit(false);
  };

  return (
    <div className="overlay" style={{ zIndex: 97 }}>
      <div className="login-form" style={{ boxShadow: "0 0 10px var(--blurry)", width: "480px" }}>
        <div className="login-title">
          <p>TOP UP SERVICE PROVIDER</p>
        </div>
        <Container mode="wrap">
          <hr className="separator-top" />
          <p className="detail-info" style={{ fontSize: "16px", fontWeight: 700 }}>
            {provider.providerName}
          </p>
          <hr className="separator" />
          <p className="detail-description">service provider will be topped up. Please complete form below</p>
          <GroupBox>
            {providerBalance && (
              <GroupElement label="Available balance" align="right">
                <p>{`N$ ${formatCurrency(providerBalance.creditBalance, 2)}`}</p>
              </GroupElement>
            )}
            <GroupElement label="Amount">
              <CurrencyBox ref={amountRef} value={amount} setValue={setAmount} />
            </GroupElement>
            <GroupElement label="Payment type">
              <ComboBox ref={optionRef} itemSource={providerTopupOptionList} fields={lookupFields} displayField="lookupContent" selectionField="lookupId" selectedValue={optionId} onSelectionChanged={setOptionId} />
            </GroupElement>
            <GroupElement label="PIN code">
              <PasswordBox ref={pinRef} password={pinCode} setPassword={setPinCode} />
            </GroupElement>
          </GroupBox>
          <hr className="separator" />
          <p className="caution">CAUTION: IF YOU ENTER INCORRECT PIN 3 TIMES THEN YOUR ACCOUNT WILL BE BLOCKED!!!</p>
          <hr className="separator-bottom" />
          <div className="login-reset">
            <button onClick={topupClick}>Top up</button>
            <button onClick={cancelClick}>Cancel</button>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ProviderTopup;
