const GroupBox = ({ title, type, children }) => {
  return (
    <div className="group-box" style={{ width: type && "100%", marginRight: type ? "0" : "5px", marginTop: type && "0", marginBottom: "5px" }}>
      {title && (
        <div className="group-box-title">
          <p>{title}</p>
        </div>
      )}
      <div className="group-box-detail" style={{ border: title ? "1px solid var(--border)" : "none" }}>
        {children}
      </div>
    </div>
  );
};

export default GroupBox;
