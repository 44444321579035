import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

const TextBox = forwardRef(({ type, maxLength, text, setText, placeHolder, pressEnter }, ref) => {
  const inputRef = useRef();

  function mounted() {
    return new Promise((res) => {
      setTimeout(res, 10);
    });
  }

  useImperativeHandle(ref, () => ({
    focus() {
      mounted().then(() => {
        inputRef.current.focus();
        inputRef.current.select();
      });
    },
  }));

  useEffect(() => {
    if (text) inputRef.current.value = text;
    else inputRef.current.value = "";
  }, [text]);

  const gotFocus = (e) => {
    e.target.select();
  };

  const keyDown = (e) => {
    if (e.key === "Enter" && pressEnter) pressEnter();
  };

  const valueChange = (e) => {
    if (setText) setText(e.target.value);
  };

  return <input type="text" ref={inputRef} className={type ? "grid-box" : "normal-box"} placeholder={placeHolder} spellCheck="false" autoComplete="false" autoCorrect="false" maxLength={maxLength} onFocus={gotFocus} onKeyDown={keyDown} onChange={valueChange} />;
});

export default TextBox;
