import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";

import { MasterProvider } from "./context/MasterContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MasterProvider>
    <App />
  </MasterProvider>
);
