import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import { CheckBox } from "../..";
import { formatCurrency, formatDate, formatNumber, getListValue } from "../../../library/helper";

const firstIcon = "M18.41,16.59L13.82,12L18.41,7.41L17,6L11,12L17,18L18.41,16.59M6,6H8V18H6V6Z";
const previousIcon = "M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z";
const nextIcon = "M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z";
const lastIcon = "M5.59,7.41L10.18,12L5.59,16.59L7,18L13,12L7,6L5.59,7.41M16,6H18V18H16V6Z";

const DataSearchGrid = forwardRef(({ itemSource, isChild, fields, currentPage, totalPage, totalRecord, onSelectItem, onDoubleClick, onNavigate, units }, ref) => {
  const [selectedRow, setSelectedRow] = useState(0);

  const gridRef = useRef();

  useImperativeHandle(ref, () => {
    return {
      grid: gridRef.current,
    };
  });

  useEffect(() => {
    setSelectedRow(0);
    onSelectItem(itemSource[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (itemSource.length > 0) setSelectedRow(0);
  }, [itemSource]);

  const keyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();

      if (selectedRow + 1 < itemSource.length) {
        onSelectItem(itemSource[selectedRow + 1]);
        setSelectedRow(selectedRow + 1);
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();

      if (selectedRow - 1 >= 0) {
        onSelectItem(itemSource[selectedRow - 1]);
        setSelectedRow(selectedRow - 1);
      }
    } else if (e.key === "PageDown") {
      e.preventDefault();

      if (currentPage === totalPage) {
        onSelectItem(itemSource[itemSource.length - 1]);
        setSelectedRow(itemSource.length - 1);
      } else {
        if (currentPage < totalPage) onNavigate(currentPage + 1);
      }
    } else if (e.key === "PageUp") {
      e.preventDefault();

      if (currentPage === 1) {
        onSelectItem(itemSource[0]);
        setSelectedRow(0);
      } else {
        if (currentPage > 1) onNavigate(currentPage - 1);
      }
    }
  };

  const rowClick = (e, rowId) => {
    if (e.detail === 1) selectItem(rowId);
    else if (e.detail === 2) {
      selectItem(rowId);
      onDoubleClick();
    }
  };

  const selectItem = (rowId) => {
    onSelectItem(itemSource[rowId]);
    setSelectedRow(rowId);
  };

  const moveFirst = () => {
    if (currentPage !== 1) onNavigate(1);
  };

  const movePrevious = () => {
    if (currentPage > 1) onNavigate(currentPage - 1);
  };

  const moveNext = () => {
    if (currentPage < totalPage) onNavigate(currentPage + 1);
  };

  const moveLast = () => {
    if (currentPage < totalPage) onNavigate(totalPage);
  };

  return (
    <div ref={gridRef} className="grid-main" tabIndex="0" onKeyDown={keyDown} style={{ margin: isChild && "10px" }}>
      <div className="grid-header">
        {fields
          .filter((p) => p.width >= 0)
          .map((col, index) => {
            return (
              <div key={col.id} style={{ minWidth: col.width !== 0 ? `${col.width}px` : null, width: col.width !== 0 ? `${col.width}px` : "100%", borderLeft: index !== 0 ? "1px solid var(--border)" : null, cursor: "default" }}>
                <p>{col.caption}</p>
              </div>
            );
          })}
      </div>
      <div className="grid-content">
        {itemSource.map((row, rowId) => {
          return (
            <div key={row[fields[0].name]} className="grid-row" style={{ borderBottom: rowId === itemSource.length - 1 ? null : "1px dotted var(--border)", background: rowId === selectedRow && "var(--light)" }} onClick={(e) => rowClick(e, rowId)}>
              {fields
                .filter((p) => p.width >= 0)
                .map((col, index) => {
                  return (
                    <div key={col.id} className="grid-cell" style={{ minWidth: col.width !== 0 ? `${col.width}px` : null, width: col.width !== 0 ? `${col.width}px` : "100%", justifyContent: col.align, borderLeft: index !== 0 ? "1px solid var(--border)" : null, fontWeight: selectedRow === rowId && "700" }}>
                      {col.type === "string" && <p>{row[col.name]}</p>}
                      {col.type === "boolean" && <CheckBox isReadOnly={true} isChecked={row[col.name]} />}
                      {col.type === "numeric" && <p>{formatNumber(row[col.name], false)}</p>}
                      {col.type === "currency" && <p>{formatCurrency(row[col.name], 2)}</p>}
                      {col.type === "percent" && <p>{formatCurrency(row[col.name], 4)}</p>}
                      {col.type === "date" && <p>{formatDate(row[col.name], "dd/mm/yyyy")}</p>}
                      {col.type === "list" && <p>{getListValue(col.typeId, row[col.name])}</p>}
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
      <div className="grid-footer">
        <div className="grid-footer-record">
          <span>{totalRecord}</span>
          <p>{totalRecord <= 1 ? (units ? units[0] : "record") : units ? units[1] : "records"}</p>
        </div>
        <div className="grid-footer-navigation">
          <svg viewBox="0 0 24 24" onClick={moveFirst}>
            <path d={firstIcon} opacity={totalPage !== 0 && currentPage !== 1 ? 1 : 0.4} />
          </svg>
          <svg viewBox="0 0 24 24" onClick={movePrevious}>
            <path d={previousIcon} opacity={totalPage !== 0 && currentPage !== 1 ? 1 : 0.4} />
          </svg>
          <p>
            Page {currentPage} of {totalPage}
          </p>
          <svg viewBox="0 0 24 24" onClick={moveNext}>
            <path d={nextIcon} opacity={totalPage !== 0 && currentPage !== totalPage ? 1 : 0.4} />
          </svg>
          <svg viewBox="0 0 24 24" onClick={moveLast}>
            <path d={lastIcon} opacity={totalPage !== 0 && currentPage !== totalPage ? 1 : 0.4} />
          </svg>
        </div>
      </div>
    </div>
  );
});

export default DataSearchGrid;
