import { useEffect } from "react";

const Spinner = () => {
  useEffect(() => {
    function keyListener(e) {
      return e.preventDefault();
    }
    document.addEventListener("keydown", keyListener);

    return () => document.removeEventListener("keydown", keyListener);
  });

  return (
    <div className="overlay">
      <div className="ring">
        <div className="ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
