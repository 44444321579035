const GroupElement = ({ label, align, detail, line, children }) => {
  return (
    <div className="group-box-element" style={{ borderBottom: line && "1px dotted var(--background)" }}>
      <div className="group-box-element-label">
        <p>{label}</p>
      </div>
      <div className="group-box-element-control" style={{ width: detail ? "100%" : "250px", justifyContent: align ? align : "flex-start" }}>
        {children}
      </div>
    </div>
  );
};

export default GroupElement;
