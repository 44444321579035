import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

const PasswordBox = forwardRef(({ password, setPassword, placeHolder, pressEnter }, ref) => {
  const passwordRef = useRef();

  function mounted() {
    return new Promise((res) => {
      setTimeout(res, 10);
    });
  }

  useImperativeHandle(ref, () => ({
    focus() {
      mounted().then(() => {
        passwordRef.current.focus();
        passwordRef.current.select();
      });
    },
  }));

  useEffect(() => {
    if (password) passwordRef.current.value = password;
    else passwordRef.current.value = "";
  }, [password]);

  const gotFocus = (e) => {
    e.target.select();
  };

  const keyDown = (e) => {
    if (e.key === "Enter" && pressEnter) pressEnter();
  };

  const valueChange = (e) => {
    if (setPassword) setPassword(e.target.value);
  };

  return <input type="password" ref={passwordRef} placeholder={placeHolder} className="normal-box" onFocus={gotFocus} onKeyDown={keyDown} onChange={valueChange} />;
});

export default PasswordBox;
