import { forwardRef, useImperativeHandle, useRef } from "react";

const checkedIcon = "M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z";
const indeterminateIcon = "M20 14H4V10H20V14Z";

const CheckBox = forwardRef(({ isReadOnly, value, setValue, onClick, onFocus }, ref) => {
  const checkBoxRef = useRef();

  function mounted() {
    return new Promise((res) => {
      setTimeout(res, 10);
    });
  }

  useImperativeHandle(ref, () => ({
    focus() {
      mounted().then(() => {
        checkBoxRef.current.focus();
      });
    },
  }));

  const valueChange = () => {
    if (!isReadOnly && setValue) {
      if (value === null) setValue(true);
      else setValue(!value);
      if (onClick) onClick();
    }
  };

  const keyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
      valueChange();
    }
  };

  const focusControl = () => {
    if (onFocus) onFocus();
  };

  return (
    <div ref={checkBoxRef} className="check-box" tabIndex={isReadOnly ? "" : "0"} onClick={valueChange} onFocus={focusControl} onKeyDown={keyDown}>
      <svg viewBox="0 0 24 24" style={{ fill: value === null ? "var(--label)" : value ? "var(--success)" : "var(--white)" }}>
        <path d={value === null ? indeterminateIcon : value ? checkedIcon : ""} />
      </svg>
    </div>
  );
});

export default CheckBox;
