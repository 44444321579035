import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useMaster } from "../../../context/MasterContext";
import { Menu } from "../../../components";
import logo from "../../../assets/logo.png";

const Header = () => {
  const { isEdit } = useMaster();
  const [title, setTitle] = useState("");
  const [showMenu, setShowMenu] = useState(false);

  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setTitle("Dashboard");
        break;
      case "/provider":
        setTitle("Service provider");
        break;
      case "/agent":
        setTitle("Agent");
        break;
      case "/gl":
        setTitle("General ledger");
        break;
      case "/report":
        setTitle("Report");
        break;
      default:
        setTitle("");
        break;
    }
  }, [location]);

  const toggleMenu = () => {
    if (!isEdit) setShowMenu(!showMenu);
  };

  return (
    <div className="header-main">
      <div className="header-logo" style={{ cursor: isEdit ? "default" : "pointer" }} onClick={toggleMenu}>
        <img src={logo} alt="" />
      </div>
      <h1>{title}</h1>
      {showMenu && <Menu onHide={() => setShowMenu(false)} />}
    </div>
  );
};

export default Header;
