import { typeList, statusList } from "../library/static";

export function validateMobile(mobile) {
  if (mobile.length !== 10) return false;

  if (!/^\d+\.?\d*$/.test(mobile)) return false;

  if (mobile.slice(0, 3) !== "081" && mobile.slice(0, 3) !== "085") return false;

  return true;
}

export function formatDate(date, formatString) {
  if (date) {
    let current = new Date(date);

    let dateParts = formatString ? formatString.split("/") : "dd/mm/yyyy".split("/");
    let result = "";

    for (let i = 0; i < 3; i++) {
      if (dateParts[i] === "yyyy") {
        result = result + current.getFullYear() + "/";
      } else if (dateParts[i] === "mm") {
        result = result + String(current.getMonth() + 1).padStart(2, "0") + "/";
      } else if (dateParts[i] === "dd") {
        result = result + String(current.getDate()).padStart(2, "0") + "/";
      }
    }

    return result.slice(0, -1);
  } else {
    return "";
  }
}

export function formatDateTime(date) {
  if (date) {
    let current = new Date(date);

    let result = String(current.getDate()).padStart(2, "0") + "/";

    result = result + String(current.getMonth() + 1).padStart(2, "0") + "/";
    result = result + current.getFullYear() + " ";

    result = result + String(current.getHours()).padStart(2, "0") + ":";
    result = result + String(current.getMinutes()).padStart(2, "0");

    return result;
  } else {
    return "";
  }
}

export function formatCurrency(value, digits) {
  let num = value.toFixed(digits).split(".");
  return `${num[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${num[1]}`;
}

export function formatNumber(value, separator) {
  if (value != null) {
    if (separator) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return value.toString();
    }
  } else {
    return "";
  }
}

export function getType(typeId) {
  return typeList.find((p) => p.lookupId === typeId).lookupContent;
}

export function getStatus(statusId) {
  return statusList.find((p) => p.lookupId === statusId).lookupContent;
}

export function getListValue(typeId, valueId) {
  let result = "";
  switch (typeId) {
    case 1:
      result = typeList.find((p) => p.lookupId === valueId).lookupContent;
      break;
    case 2:
      result = statusList.find((p) => p.lookupId === valueId).lookupContent;
      break;
    default:
      result = "";
      break;
  }

  return result;
}
