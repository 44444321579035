import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

const dropdownIcon = "M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z";

const ComboBox = forwardRef(({ type, itemSource, fields, displayField, selectionField, selectedValue, onSelectionChanged, placeHolder }, ref) => {
  const [hasFocus, setHasFocus] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectionList, setSelectionList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [mode, setMode] = useState(0);
  const [top, setTop] = useState(0);

  const controlBoxRef = useRef();
  const inputRef = useRef();
  const dropDownRef = useRef();

  function mounted() {
    return new Promise((res) => {
      setTimeout(res, 10);
    });
  }

  useImperativeHandle(ref, () => ({
    focus() {
      mounted().then(() => {
        inputRef.current.focus();
        inputRef.current.select();
      });
    },
  }));

  useEffect(() => {
    function handleClickOutside(event) {
      if (hasFocus && controlBoxRef.current && !controlBoxRef.current.contains(event.target)) {
        setShowDropDown(false);
        setHasFocus(false);

        if (mode === 1) {
          if (selectedIndex === -1) {
            if (onSelectionChanged) onSelectionChanged(null);
            inputRef.current.value = "";
          } else {
            if (onSelectionChanged) onSelectionChanged(selectionList[selectedIndex][selectionField]);
            inputRef.current.value = selectionList[selectedIndex][displayField];
          }

          setMode(0);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    let list = [];

    if (itemSource)
      for (let i = 0; i < itemSource.length; i++) {
        let item = { index: i, ...itemSource[i], ref: React.createRef() };
        list.push(item);
      }

    setSelectionList(list);

    if (itemSource) {
      let dropdownHeight = itemSource.length * 25 + 2 > 202 ? 202 : itemSource.length * 25 + 2;

      if (window.innerHeight - controlBoxRef.current.getBoundingClientRect().y - controlBoxRef.current.getBoundingClientRect().height > dropdownHeight) setTop(controlBoxRef.current.getBoundingClientRect().height + 1);
      else setTop(controlBoxRef.current.offsetHeight - controlBoxRef.current.getBoundingClientRect().height - dropdownHeight - 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectionField && selectedValue && displayField) {
      let item = selectionList.find((p) => p[selectionField] === selectedValue);

      if (item) {
        setSelectedIndex(item.index);
        inputRef.current.value = item[displayField];
      } else {
        setSelectedIndex(-1);
        inputRef.current.value = "";
      }
    } else {
      setSelectedIndex(-1);
      inputRef.current.value = "";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionList, selectedValue]);

  useEffect(() => {
    if (showDropDown && selectionList.length > 0) {
      if (selectedIndex !== -1) {
        let item = selectionList[selectedIndex];
        if (item) selectionList[selectedIndex].ref.current.scrollIntoView({ block: "nearest" });
        else selectionList[0].ref.current.scrollIntoView({ block: "nearest" });
      } else selectionList[0].ref.current.scrollIntoView({ block: "nearest" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDropDown, selectedIndex]);

  const focusControl = () => {
    setHasFocus(true);
    inputRef.current.select();
  };

  const toggleDropDown = () => {
    setHasFocus(true);
    setShowDropDown(!showDropDown);
    inputRef.current.focus();
    inputRef.current.select();
  };

  const selectItem = (item) => {
    if (onSelectionChanged) onSelectionChanged(item[selectionField]);
    setSelectedIndex(item.index);
    inputRef.current.value = item[displayField];
    setShowDropDown(false);
    inputRef.current.focus();
    inputRef.current.select();
    setMode(0);
  };

  const valueChange = (e) => {
    if (mode === 0) setMode(1);

    const text = e.target.value;

    if (text.length > 0) {
      const regex = new RegExp(`^${text}`, "i");
      let filteredResult = selectionList.filter((item) => regex.test(item[displayField]));
      if (filteredResult.length > 0) setSelectedIndex(filteredResult[0].index);
      else setSelectedIndex(-1);
    } else setSelectedIndex(-1);

    setShowDropDown(true);
  };

  const keyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();

      if (selectedIndex !== -1) {
        if (mode === 0) {
          if (selectedIndex + 1 < selectionList.length) {
            e.target.value = selectionList[selectedIndex + 1][displayField];
            if (onSelectionChanged) onSelectionChanged(selectionList[selectedIndex + 1][selectionField]);
            setSelectedIndex(selectedIndex + 1);
          }
        } else {
          e.target.value = selectionList[selectedIndex][displayField];
          if (onSelectionChanged) onSelectionChanged(selectionList[selectedIndex][selectionField]);
        }
      } else {
        e.target.value = selectionList[0][displayField];
        if (onSelectionChanged) onSelectionChanged(selectionList[0][selectionField]);
        setSelectedIndex(0);
      }

      if (mode === 1) setMode(0);

      e.target.select();
    } else if (e.key === "ArrowUp") {
      e.preventDefault();

      if (selectedIndex !== -1) {
        if (mode === 0) {
          if (selectedIndex - 1 >= 0) {
            e.target.value = selectionList[selectedIndex - 1][displayField];
            if (onSelectionChanged) onSelectionChanged(selectionList[selectedIndex - 1][selectionField]);
            setSelectedIndex(selectedIndex - 1);
          }
        } else {
          e.target.value = selectionList[selectedIndex][displayField];
          if (onSelectionChanged) onSelectionChanged(selectionList[selectedIndex][selectionField]);
        }
      }

      if (mode === 1) setMode(0);

      e.target.select();
    } else if (e.key === "Tab") {
      setShowDropDown(false);
      setHasFocus(false);

      if (mode === 1) {
        if (selectedIndex !== -1) {
          e.target.value = selectionList[selectedIndex][displayField];
          if (onSelectionChanged) onSelectionChanged(selectionList[selectedIndex][selectionField]);
        } else {
          e.target.value = "";
          if (onSelectionChanged) onSelectionChanged(null);
        }

        setMode(0);
      }
    } else if (e.key === "Escape") {
      if (showDropDown) setShowDropDown(false);
      if (mode === 1) setMode(0);

      if (selectedValue) {
        let item = selectionList.find((p) => p[selectionField] === selectedValue);
        if (item) {
          setSelectedIndex(item.index);
          inputRef.current.value = item[displayField];
        } else {
          setSelectedIndex(-1);
          inputRef.current.value = "";
        }
      } else {
        setSelectedIndex(-1);
        inputRef.current.value = "";
      }

      e.target.focus();
      e.target.select();
    }
  };

  return (
    <div ref={controlBoxRef} className="control-box" style={{ height: type ? "100%" : "2.5rem" }}>
      <div className={type ? "control-grid" : !hasFocus ? "control-default" : "control-focus"}>
        <input type="text" ref={inputRef} className="input-box" placeholder={placeHolder} spellCheck="false" autoComplete="false" autoCorrect="false" onFocus={focusControl} onChange={valueChange} onKeyDown={keyDown} />
        <div className="drop-button" onClick={toggleDropDown}>
          <svg viewBox="0 0 24 24" style={{ fill: hasFocus ? "var(--primary)" : "var(--dark)" }}>
            <path d={dropdownIcon} />
          </svg>
        </div>
      </div>
      {selectionList.length > 0 && showDropDown && (
        <div ref={dropDownRef} className="drop-down" style={{ maxHeight: "202px", minWidth: controlBoxRef.current.offsetWidth, top: `${top}px` }}>
          {selectionList.map((item) => {
            return (
              <div key={item.index} ref={item.ref} className={`${item.index !== selectedIndex ? "normal-item" : mode === 0 ? "selected-item" : "suggested-item"}`} onClick={() => selectItem(item)}>
                {fields
                  .filter((p) => p.width >= 0)
                  .map((col, index) => {
                    return (
                      <div key={col.id} style={{ display: "flex", borderLeft: index !== 0 ? "1px solid var(--border)" : "none", width: col.width === 0 ? "100%" : `${col.width}px`, alignItems: "left" }}>
                        <p style={{ margin: "auto 10px" }}>{item[col.name]}</p>
                      </div>
                    );
                  })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
});

export default ComboBox;
