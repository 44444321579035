import { useState, useRef, useEffect } from "react";

const Toolbar = ({ itemSource, isChild, onClick }) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const dropDownRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setShowDropDown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownRef]);

  const itemClick = (menuId) => {
    if (onClick) onClick(menuId);
  };

  return (
    <div className="toolbar" style={{ margin: isChild && "0 10px 10px 10px" }}>
      <ul className="toolbar-menu">
        {itemSource.map((item) => {
          return (
            <li key={item.menuId}>
              {item.children ? (
                <div ref={dropDownRef} style={{ display: "flex" }}>
                  <div className="toolbar-item" style={{ background: showDropDown && "var(--primary)", border: showDropDown && "1px solid var(--primary)" }} onClick={() => setShowDropDown(!showDropDown)}>
                    <svg viewBox="0 0 24 24" style={{ fill: showDropDown && "var(--white)" }}>
                      <path d={item.icon}></path>
                    </svg>
                    <p style={{ color: showDropDown && "var(--white)" }}>{item.caption}</p>
                  </div>
                  <ul className="toolbar-dropdown" style={{ display: showDropDown ? "inline-block" : "none", width: `${item.width}px` }}>
                    {item.children.map((child, index) => (
                      <li key={child.childId} className="toolbar-child" style={{ borderBottom: index !== item.children.length - 1 ? "1px dotted var(--background)" : "none" }} onClick={() => itemClick(child.moduleId)}>
                        <svg viewBox="0 0 24 24">
                          <path d={child.icon}></path>
                        </svg>
                        <p>{child.caption}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className="toolbar-item" onMouseOver={() => setShowDropDown(false)} onClick={() => itemClick(item.menuId)}>
                  <svg viewBox="0 0 24 24">
                    <path d={item.icon}></path>
                  </svg>
                  <p>{item.caption}</p>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Toolbar;
