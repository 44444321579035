import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useMaster } from "../../context/MasterContext";

import { Container, StackPanel, Toolbar, DataGrid, GroupBox, GroupElement } from "../../controls";
import { ProviderBalance, ProviderTopup } from "../../components";
import { providerFields } from "../../library/static";

import * as icons from "../../library/icons";

const ProviderPage = () => {
  const { chartAccountList, providerList } = useMaster();
  const [menuList, setMenuList] = useState([]);
  const [provider, setProvider] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [showBalance, setShowBalance] = useState(false);
  const [showTopup, setShowTopup] = useState(false);

  const gridRef = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    gridRef.current.focus();

    if (providerList.length > 0) setProvider(providerList[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let tempList = [];

    if (provider) {
      tempList.push({ menuId: 0, caption: "Check balance", icon: icons["balanceIcon"] });
      tempList.push({ menuId: 1, caption: "Top-up", icon: icons["topupIcon"] });
      tempList.push({ menuId: 2, caption: "Invoice", icon: icons["invoiceIcon"] });
      tempList.push({ menuId: 3, caption: "Statement", icon: icons["statementIcon"] });
    }

    tempList.push({ menuId: 99, caption: "Close", icon: icons["closeIcon"] });

    setMenuList(tempList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  const onSort = (field, order) => {
    setSortField(field);
    setSortOrder(order);
  };

  const menuClick = (menuId) => {
    switch (menuId) {
      case 0:
        setShowBalance(true);
        break;
      case 1:
        setShowTopup(true);
        break;
      case 2:
        break;
      case 3:
        break;
      default:
        navigate("/");
        break;
    }
  };

  return (
    <>
      <Container mode="main">
        <Toolbar itemSource={menuList} onClick={(menuId) => menuClick(menuId)} />
        <StackPanel orientation="row">
          <StackPanel orientation="column" side="left">
            <DataGrid ref={gridRef} itemSource={providerList} fields={providerFields} allowSort={true} sortField={sortField} sortOrder={sortOrder} onSort={onSort} selectedItem={provider} onSelectItem={setProvider} units={["provider", "providers"]} />
          </StackPanel>
          {provider && (
            <StackPanel orientation="column" side="right">
              <GroupBox title="Provider detail" type="detail">
                <GroupElement label="Name" detail="auto" line="y">
                  <p>{provider.providerName}</p>
                </GroupElement>
                <GroupElement label="Logo" detail="auto">
                  <div style={{ background: `#${provider.color}`, margin: "10px 0", borderRadius: "10px", padding: "10px" }}>
                    <img src={`${process.env.REACT_APP_SYSTEM_URL}${provider.logo}`} alt={provider.providerName} style={{ height: "40px", width: "auto", margin: "auto" }} />
                  </div>
                </GroupElement>
              </GroupBox>
              <GroupBox title="General ledger interface" type="detail">
                <GroupElement label="Control account" detail="auto" line="y">
                  <p className="key">{chartAccountList.find((p) => p.accountId === provider.controlId).accountCode}</p>
                  <p>{chartAccountList.find((p) => p.accountId === provider.controlId).accountName}</p>
                </GroupElement>
                <GroupElement label="Acrrued commission" detail="auto">
                  <p className="key">{chartAccountList.find((p) => p.accountId === provider.commissionId).accountCode}</p>
                  <p>{chartAccountList.find((p) => p.accountId === provider.commissionId).accountName}</p>
                </GroupElement>
                <GroupElement label="Receivable" detail="auto">
                  <p className="key">{chartAccountList.find((p) => p.accountId === provider.receivableId).accountCode}</p>
                  <p>{chartAccountList.find((p) => p.accountId === provider.receivableId).accountName}</p>
                </GroupElement>
                <GroupElement label="Payable" detail="auto">
                  <p className="key">{chartAccountList.find((p) => p.accountId === provider.payableId).accountCode}</p>
                  <p>{chartAccountList.find((p) => p.accountId === provider.payableId).accountName}</p>
                </GroupElement>
                <GroupElement label="Income" detail="auto">
                  <p className="key">{chartAccountList.find((p) => p.accountId === provider.incomeId).accountCode}</p>
                  <p>{chartAccountList.find((p) => p.accountId === provider.incomeId).accountName}</p>
                </GroupElement>
              </GroupBox>
            </StackPanel>
          )}
        </StackPanel>
      </Container>
      {showBalance && <ProviderBalance provider={provider} onHide={() => setShowBalance(false)} />}
      {showTopup && <ProviderTopup provider={provider} onHide={() => setShowTopup(false)} />}
    </>
  );
};

export default ProviderPage;
