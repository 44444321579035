import { useRef, useEffect } from "react";

const confirmIcon = "M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z";
const notificationIcon = "M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z";
const errorIcon = "M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z";
const warningIcon = "M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16";

const systemErrorMessage = "System error has occured, please contact administrator";

const Dialog = ({ type, message, confirm, dismiss }) => {
  const dialogRef = useRef();

  const handleTabKey = (e) => {
    const focusableElements = dialogRef.current.querySelectorAll("button");
    const firstElement = focusableElements[0];
    const lastElement = focusableElements.length === 1 ? focusableElements[0] : focusableElements[focusableElements.length - 1];

    if (focusableElements.length > 1) {
      if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        return e.preventDefault();
      }

      if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        e.preventDefault();
      }
    } else {
      firstElement.focus();
      e.preventDefault();
    }
  };

  const handleEscape = () => {
    dismiss();
  };

  const handleEnter = () => {
    confirm();
  };

  const keyListenersMap = new Map([
    [9, handleTabKey],
    [27, handleEscape],
    [13, handleEnter],
  ]);

  useEffect(() => {
    function keyListener(e) {
      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    }

    document.addEventListener("keydown", keyListener);

    return () => document.removeEventListener("keydown", keyListener);
  });

  useEffect(() => {
    dialogRef.current.focus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="overlay" style={{ zIndex: 98 }}>
      <div ref={dialogRef} className="dialog" tabIndex="0">
        <div className="dialog-title">
          <p>{type === 1 ? "Notification" : type === 98 ? "Confirmation" : type === 99 ? "Exception" : "Validation"}</p>
        </div>
        <div className="dialog-content">
          <div className="dialog-icon">
            <svg viewBox="0 0 24 24" style={{ fill: type === 1 ? "var(--success)" : type === 98 ? "var(--primary)" : type === 99 ? "var(--error)" : "var(--warning)" }}>
              <path d={type === 1 ? notificationIcon : type === 98 ? confirmIcon : type === 99 ? errorIcon : warningIcon} />
            </svg>
          </div>
          <div className="dialog-message">
            {type === 99 ? (
              <>
                <p>{systemErrorMessage}</p>
                <br />
                <p>{message}</p>
              </>
            ) : (
              <p dangerouslySetInnerHTML={{ __html: message }} />
            )}
          </div>
        </div>
        <div className="dialog-button" tabIndex="0">
          <button onClick={confirm}>{type === 98 ? "Yes" : "OK"}</button>
          {type === 98 && <button onClick={dismiss}>No</button>}
        </div>
      </div>
    </div>
  );
};

export default Dialog;
