import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IdleTimerProvider } from "react-idle-timer";
import { useMaster } from "../../../context/MasterContext";
import { Header } from "../..";

const Layout = () => {
  const { user, setUser, socket } = useMaster();

  const navigate = useNavigate();

  const onIdle = () => {
    if (user) {
      setUser(null);

      socket.emit("signOut");
      navigate("/login");
    }
  };

  return (
    <IdleTimerProvider timeout={100 * 60 * 1000} onIdle={onIdle}>
      {user && <Header />}
      <Outlet />
    </IdleTimerProvider>
  );
};

export default Layout;
