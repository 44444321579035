import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useMaster, useMessage } from "../../../context/MasterContext";
import { Container, GroupBox, GroupElement, CurrencyBox, ComboBox, PasswordBox } from "../../../controls";
import { agentTopupOptionList, lookupFields } from "../../../library/static";
import { formatCurrency } from "../../../library/helper";

import axios from "../../../library/service";

const AgentTopup = ({ agent, setAgent, onHide }) => {
  const { socket, setIsBusy, company, setUser, token, setIsEdit } = useMaster();
  const [optionId, setOptionId] = useState(agent.allowCredit ? 1 : 2);
  const [amount, setAmount] = useState(null);
  const [pinCode, setPinCode] = useState("");
  const [attempt, setAttempt] = useState(0);

  const amountRef = useRef();
  const optionRef = useRef();
  const pinRef = useRef();

  const navigate = useNavigate();

  const { messageBox } = useMessage();

  useEffect(() => {
    setIsEdit(true);
    amountRef.current.focus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const topupClick = () => {
    if (amount === null || amount === 0) {
      messageBox({ type: 2, message: "Amount required" }).then(() => {
        amountRef.current.focus();
      });
    } else if (optionId === null) {
      messageBox({ type: 2, message: "Choose payment type" }).then(() => {
        optionRef.current.focus();
      });
    } else if (pinCode === "") {
      messageBox({ type: 2, message: "Enter your PIN code" }).then(() => {
        pinRef.current.focus();
      });
    } else if (pinCode.length !== 4) {
      messageBox({ type: 2, message: "Invalid PIN code, try again" }).then(() => {
        pinRef.current.focus();
      });
    } else if (!/^\d+$/.test(pinCode)) {
      messageBox({ type: 2, message: "Invalid PIN code, try again" }).then(() => {
        pinRef.current.focus();
      });
    } else {
      topup();
    }
  };

  const topup = async () => {
    setIsBusy(true);

    const parameter = {
      agentId: agent.agentId,
      amount,
      optionId,
      debitAccountId: optionId === 2 ? company.bankAccountId : company.creditBalanceId,
      creditAccountId: company.mainWalletId,
      pinCode,
      attempt,
    };

    try {
      await axios.post("/agent/topupAgent", JSON.stringify(parameter), { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then((response) => {
        setIsBusy(false);

        let result = response.data;

        if (result.type !== 1) {
          if (result.type === 96) {
            setAttempt(attempt + 1);
            messageBox({ type: result.type, message: `${result.message}\n\n${2 - attempt} ${2 - attempt > 1 ? "ATTEMPTS" : "ATTEMPT"} LEFT!!!` }).then(() => {
              pinRef.current.focus();
            });
          } else if (result.type === 97) {
            messageBox({ type: 99, message: result.message }).then(() => {
              setIsEdit(false);
              setUser(null);
              socket.emit("signOut");
              navigate("/");
            });
          } else {
            messageBox({ type: result.type, message: result.message }).then(() => {
              pinRef.current.focus();
            });
          }
        } else {
          messageBox({ type: result.type, message: result.message }).then(() => {
            const updatedAgent = { ...agent };
            updatedAgent.mainWallet = updatedAgent.mainWallet + amount;
            if (optionId === 1) updatedAgent.creditBalance = updatedAgent.creditBalance + amount;
            setAgent(updatedAgent);
            onHide();
            setIsEdit(false);
          });
        }
      });
    } catch (error) {
      setIsBusy(false);

      messageBox({ type: 99, message: error.message }).then(() => {
        pinRef.current.focus();
      });
    }
  };

  const cancelClick = () => {
    onHide();
    setIsEdit(false);
  };

  return (
    <div className="overlay" style={{ zIndex: 97 }}>
      <div className="login-form" style={{ boxShadow: "0 0 10px var(--blurry)", width: "480px" }}>
        <div className="login-title">
          <p>TOPUP AGENT</p>
        </div>
        <Container mode="wrap">
          <hr className="separator-top" />
          <p className="detail-info">
            {agent.agentName} - {agent.nationalId}
          </p>
          <p className="detail-info">{agent.mobile}</p>
          <hr className="separator" />
          <p className="detail-description">agent will be topped up. Please complete form below</p>
          <GroupBox type="child">
            <GroupElement label="Main wallet" align="right">
              <p>{`N$ ${formatCurrency(agent.mainWallet, 2)}`}</p>
            </GroupElement>
            <GroupElement label="Amount">
              <CurrencyBox ref={amountRef} value={amount} setValue={setAmount} />
            </GroupElement>
            {agent.allowCredit && (
              <GroupElement label="Payment type">
                <ComboBox ref={optionRef} itemSource={agentTopupOptionList} fields={lookupFields} displayField="lookupContent" selectionField="lookupId" selectedValue={optionId} onSelectionChanged={setOptionId} />
              </GroupElement>
            )}
            <GroupElement label="PIN code">
              <PasswordBox ref={pinRef} password={pinCode} setPassword={setPinCode} />
            </GroupElement>
          </GroupBox>
          <p className="caution">CAUTION: IF YOU ENTER INCORRECT PIN 3 TIMES THEN YOUR ACCOUNT WILL BE BLOCKED!!!</p>
          <hr className="separator-bottom" />
          <div className="login-reset">
            <button onClick={topupClick}>Topup</button>
            <button onClick={cancelClick}>Cancel</button>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default AgentTopup;
