import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import { CheckBox } from "../..";
import { formatCurrency, formatDate, formatNumber } from "../../../library/helper";

const firstIcon = "M18.41,16.59L13.82,12L18.41,7.41L17,6L11,12L17,18L18.41,16.59M6,6H8V18H6V6Z";
const previousIcon = "M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z";
const nextIcon = "M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z";
const lastIcon = "M5.59,7.41L10.18,12L5.59,16.59L7,18L13,12L7,6L5.59,7.41M16,6H18V18H16V6Z";
const downThinIcon = "M7.03 13.92H11.03V5L13.04 4.97V13.92H17.03L12.03 18.92Z";
const upThinIcon = "M7.03 9.97H11.03V18.89L13.04 18.92V9.97H17.03L12.03 4.97Z";

const DataGrid = forwardRef(({ itemSource, fields, allowSort, sortField, sortOrder, onSort, selectedItem, onSelectItem, units }, ref) => {
  const [pageSize, setPageSize] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(null);
  const [displayList, setDisplayList] = useState([]);
  const [displayRow, setDisplayRow] = useState(null);

  const gridRef = useRef();

  function mounted() {
    return new Promise((res) => {
      setTimeout(res, 10);
    });
  }

  useImperativeHandle(ref, () => ({
    focus() {
      mounted().then(() => {
        gridRef.current.focus();
      });
    },
  }));

  useEffect(() => {
    const resizeListener = () => {
      setDisplayList([]);

      mounted().then(() => updateGrid());
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  });

  useEffect(() => {
    mounted().then(() => updateGrid());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSource, allowSort, sortField, sortOrder]);

  useEffect(() => {
    if (selectedItem) {
      let item = displayList.find((p) => p[fields[0].name] === selectedItem[fields[0].name]);

      setDisplayRow(item);

      if (item) setCurrentPage(item.pageId);
      else setCurrentPage(0);
    } else {
      setDisplayRow(null);
      setCurrentPage(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, displayList]);

  const updateGrid = () => {
    let size = Math.floor((gridRef.current.clientHeight - 70) / 29);

    setPageSize(size);

    let page = 0;

    if (itemSource.length > 0 && size > 0) page = itemSource.length % size === 0 ? Math.floor(itemSource.length / size) : Math.floor(itemSource.length / size) + 1;
    else page = 0;

    setTotalPage(page);

    let list = [];

    let j = 0;
    let p = 0;

    let sortedList = [];

    if (itemSource.length > 0 && size > 0) {
      if (allowSort && sortField && sortOrder) {
        let field = fields.find((p) => p.name === sortField);

        if (field) {
          if (field.type === "string") {
            if (sortOrder === "ascending") sortedList = itemSource.sort((a, b) => a[sortField].toLowerCase().localeCompare(b[sortField].toLowerCase()) || a[fields[0].name] - b[fields[0].name]);
            else sortedList = itemSource.sort((a, b) => b[sortField].toLowerCase().localeCompare(a[sortField].toLowerCase()) || a[fields[0].name] - b[fields[0].name]);
          } else if (field.type === "boolean") {
            if (sortOrder === "ascending") sortedList = itemSource.sort((a, b) => Number(a[sortField]) - Number(b[sortField]) || a[fields[0].name] - b[fields[0].name]);
            else sortedList = itemSource.sort((a, b) => Number(b[sortField]) - Number(a[sortField]) || a[fields[0].name] - b[fields[0].name]);
          } else if (field.type === "date") {
            if (sortOrder === "ascending") sortedList = itemSource.sort((a, b) => (a[sortField] > b[sortField] ? 1 : -1) || a[fields[0].name] - b[fields[0].name]);
            else sortedList = itemSource.sort((a, b) => (a[sortField] < b[sortField] ? 1 : -1) || a[fields[0].name] - b[fields[0].name]);
          } else {
            if (sortOrder === "ascending") sortedList = itemSource.sort((a, b) => a[sortField] - b[sortField] || a[fields[0].name] - b[fields[0].name]);
            else sortedList = itemSource.sort((a, b) => b[sortField] - a[sortField] || a[fields[0].name] - b[fields[0].name]);
          }
        } else sortedList = itemSource.sort((a, b) => (a[fields[0].name] > b[fields[0].name] ? 1 : -1));
      } else sortedList = itemSource.sort((a, b) => (a[fields[0].name] > b[fields[0].name] ? 1 : -1));

      for (let i = 0; i < sortedList.length; i++) {
        list[i] = { id: i, displayId: j, pageId: p, ...sortedList[i] };
        if (j + 1 === size) {
          j = 0;
          p++;
        } else j++;
      }
    }

    setDisplayList(list);
  };

  const selectItem = (item) => {
    setDisplayRow(item);

    if (onSelectItem) {
      if (item) {
        let selected = itemSource.find((p) => p[fields[0].name] === item[fields[0].name]);
        onSelectItem(selected);
      } else onSelectItem(null);
    }
  };

  const sortGrid = (col) => {
    if (onSort && allowSort) {
      if (sortField !== col.name) onSort(col.name, "ascending");
      else onSort(col.name, sortOrder === "ascending" ? "descending" : "ascending");
    }
  };

  const removeSort = () => {
    if (onSort && allowSort) {
      onSort(null, null);
    }
  };

  const keyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();

      if (displayRow) {
        let max = pageSize;
        if (displayRow.pageId === totalPage - 1) max = displayList.filter((p) => p.pageId === displayRow.pageId).length;

        if (displayRow.displayId + 1 < max) {
          let item = displayList.find((p) => p.displayId === displayRow.displayId + 1 && p.pageId === displayRow.pageId);
          selectItem(item);
        }
      } else {
        let item = displayList.find((p) => p.displayId === 0 && p.pageId === currentPage);
        selectItem(item);
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();

      if (displayRow && displayRow.displayId - 1 >= 0) {
        let item = displayList.find((p) => p.displayId === displayRow.displayId - 1 && p.pageId === displayRow.pageId);
        selectItem(item);
      }
    } else if (e.key === "PageDown") {
      e.preventDefault();

      if (currentPage + 1 < totalPage) {
        setCurrentPage(currentPage + 1);
        selectItem(null);
      }
    } else if (e.key === "PageUp") {
      e.preventDefault();

      if (currentPage - 1 >= 0) {
        setCurrentPage(currentPage - 1);
        selectItem(null);
      }
    }
  };

  const moveFirst = () => {
    if (totalPage > 0 && currentPage !== 0) {
      setCurrentPage(0);
      selectItem(null);
    }
  };

  const movePrevious = () => {
    if (totalPage > 0 && currentPage - 1 >= 0) {
      setCurrentPage(currentPage - 1);
      selectItem(null);
    }
  };

  const moveNext = () => {
    if (totalPage > 0 && currentPage + 1 < totalPage) {
      setCurrentPage(currentPage + 1);
      selectItem(null);
    }
  };

  const moveLast = () => {
    if (totalPage > 0 && currentPage !== totalPage - 1) {
      setCurrentPage(totalPage - 1);
      selectItem(null);
    }
  };

  return (
    <div ref={gridRef} className="grid-main" tabIndex="0" onKeyDown={keyDown}>
      <div className="grid-header">
        {fields
          .filter((p) => p.width >= 0)
          .map((col, index) => {
            return (
              <div key={col.id} draggable={allowSort && col.name === sortField} style={{ minWidth: col.width !== 0 ? `${col.width}px` : null, width: col.width !== 0 ? `${col.width}px` : "100%", borderLeft: index !== 0 ? "1px solid var(--border)" : null, cursor: allowSort ? "pointer" : "default" }} onClick={() => sortGrid(col)} onDragEnd={removeSort}>
                {col.name === sortField && sortOrder && (
                  <svg viewBox="0 0 24 24">
                    <path d={sortOrder === "ascending" ? downThinIcon : upThinIcon} />
                  </svg>
                )}
                <p>{col.caption}</p>
              </div>
            );
          })}
      </div>
      <div className="grid-content">
        {displayList
          .filter((p) => p.pageId === currentPage)
          .map((row) => {
            return (
              <div key={row.id} className="grid-row" style={{ background: displayRow ? (row.id === displayRow.id ? "var(--light)" : null) : null, borderBottom: row.displayId === pageSize - 1 ? null : "1px dotted var(--border)" }} onClick={() => selectItem(row)}>
                {fields
                  .filter((p) => p.width >= 0)
                  .map((col, index) => {
                    return (
                      <div key={col.id} className="grid-cell" style={{ fontWeight: row === displayRow && "700", minWidth: col.width !== 0 ? `${col.width}px` : null, width: col.width !== 0 ? `${col.width}px` : "100%", justifyContent: col.align, borderLeft: index !== 0 ? "1px solid var(--border)" : null }}>
                        {col.type === "string" && <p>{row[col.name]}</p>}
                        {col.type === "boolean" && <CheckBox isReadOnly={true} isChecked={row[col.name]} />}
                        {col.type === "numeric" && <p>{formatNumber(row[col.name], false)}</p>}
                        {col.type === "currency" && <p>{formatCurrency(row[col.name], 2)}</p>}
                        {col.type === "percent" && <p>{formatCurrency(row[col.name], 4)}</p>}
                        {col.type === "date" && <p>{formatDate(row[col.name], "dd/mm/yyyy")}</p>}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </div>
      <div className="grid-footer">
        <div className="grid-footer-record">
          <span>{itemSource.length}</span>
          <p>{itemSource.length <= 1 ? (units ? units[0] : "record") : units ? units[1] : "records"}</p>
        </div>
        <div className="grid-footer-navigation">
          <svg viewBox="0 0 24 24" onClick={moveFirst}>
            <path d={firstIcon} opacity={currentPage !== 0 ? 1 : 0.4} />
          </svg>
          <svg viewBox="0 0 24 24" onClick={movePrevious}>
            <path d={previousIcon} opacity={currentPage !== 0 ? 1 : 0.4} />
          </svg>
          <p>
            Page {currentPage + 1} of {totalPage}
          </p>
          <svg viewBox="0 0 24 24" onClick={moveNext}>
            <path d={nextIcon} opacity={currentPage !== totalPage - 1 ? 1 : 0.4} />
          </svg>
          <svg viewBox="0 0 24 24" onClick={moveLast}>
            <path d={lastIcon} opacity={currentPage !== totalPage - 1 ? 1 : 0.4} />
          </svg>
        </div>
      </div>
    </div>
  );
});

export default DataGrid;
