import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useMaster } from "../../../context/MasterContext";

const Authentication = () => {
  const { user } = useMaster();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default Authentication;
