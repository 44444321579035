import { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";

import { Spinner, Dialog } from "../components";

const MasterContext = createContext({
  socket: null,
  setSocket: null,
  isBusy: false,
  setIsBusy: null,
  isEdit: false,
  setIsEdit: null,
  user: null,
  setUser: null,
  token: null,
  setToken: null,
  company: null,
  setCompany: null,
  chartAccountList: null,
  setChartAccountList: null,
  providerList: null,
  setProviderList: null,
  categoryList: null,
  setCategoryList: null,
  vendorList: null,
  setVendorList: null,
  productList: null,
  setProductList: null,
});

export function MasterProvider({ children }) {
  const [socket, setSocket] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [company, setCompany] = useState(null);
  const [chartAccountList, setChartAccountList] = useState(null);
  const [providerList, setProviderList] = useState(null);
  const [categoryList, setCategoryList] = useState(null);
  const [vendorList, setVendorList] = useState(null);
  const [productList, setProductList] = useState(null);

  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState({});

  useEffect(() => {
    const socketIo = io(`${process.env.REACT_APP_BASE_URL}`);
    setSocket(socketIo);

    return () => {
      socketIo.disconnect();
      setSocket(null);
    };
  }, []);

  const dialog = ({ type, message, actionCallback }) => {
    setOpen(true);
    setConfig({ type, message, actionCallback });
  };

  const reset = () => {
    setOpen(false);
    setConfig({});
  };

  const confirm = () => {
    reset();
    config.actionCallback(true);
  };

  const dismiss = () => {
    reset();
    config.actionCallback(false);
  };

  return (
    <MasterContext.Provider value={{ socket, setSocket, isEdit, setIsEdit, isBusy, setIsBusy, user, setUser, token, setToken, company, setCompany, chartAccountList, setChartAccountList, providerList, setProviderList, categoryList, setCategoryList, vendorList, setVendorList, productList, setProductList, dialog }}>
      {isBusy && <Spinner />}
      {open && <Dialog type={config?.type} message={config?.message} confirm={confirm} dismiss={dismiss} />}
      {children}
    </MasterContext.Provider>
  );
}

export function useMaster() {
  const context = useContext(MasterContext);

  return context;
}

export function useMessage() {
  const { dialog } = useContext(MasterContext);

  const messageBox = ({ ...options }) =>
    new Promise((res) => {
      dialog({ actionCallback: res, ...options });
    });

  return { messageBox };
}
