import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { useMaster, useMessage } from "./context/MasterContext";
import { Authentication, Layout } from "./components";

import * as pages from "./pages";
import "./App.css";

import axios from "./library/service";

function App() {
  const { setCompany, setChartAccountList, setProviderList, setCategoryList, setVendorList, setProductList } = useMaster();

  const { messageBox } = useMessage();

  useEffect(() => {
    initializeApp();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeApp = async () => {
    const parameter = { versionId: process.env.REACT_APP_VERSION };

    try {
      await axios.post("/", JSON.stringify(parameter)).then((response) => {
        let result = response.data;

        if (result.type !== 1) {
          messageBox({ type: result.type, message: result.message });
        } else {
          setCompany(result.company);
          setChartAccountList(result.chartAccountList);
          setProviderList(result.providerList);
          setCategoryList(result.categoryList);
          setVendorList(result.vendorList5);
          setProductList(result.productList);
        }
      });
    } catch (error) {
      messageBox({ type: 99, message: error.message });
    }
  };

  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path="login" element={<pages.LoginPage />} />
          <Route element={<Authentication />}>
            <Route path="/" element={<pages.DashboardPage />} />
            <Route path="/provider" element={<pages.ProviderPage />} />
            <Route path="/agent" element={<pages.AgentPage />} />
            <Route path="/gl" element={<pages.GeneralLedgerPage />} />
            <Route path="/report" element={<pages.ReportPage />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
