import { useEffect, useRef, useState } from "react";

import { useMaster, useMessage } from "../../../context/MasterContext";
import { Toolbar, StackPanel, ParameterBox, ParameterElement, TextBox, ComboBox, DataSearchGrid } from "../../../controls";
import { lookupFields, statusList } from "../../../library/static";

import * as icons from "../../../library/icons";
import axios from "../../../library/service";

const fields = [
  { id: 0, name: "agentId", caption: "Agent Id", width: -1 },
  { id: 1, name: "mobile", caption: "Mobile", width: 110, align: "left", type: "string" },
  { id: 2, name: "nationalId", caption: "ID number", width: 110, align: "left", type: "string" },
  { id: 3, name: "agentName", caption: "Agent name", width: 0, align: "left", type: "string" },
  { id: 4, name: "typeId", caption: "Type", width: 150, align: "left", type: "list", typeId: 1 },
  { id: 5, name: "statusId", caption: "Status", width: 110, align: "left", type: "list", typeId: 2 },
];

const AgentSearch = ({ setAgent, onHide }) => {
  const { setIsBusy, setIsEdit, token } = useMaster();
  const [menuList, setMenuList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nationalId, setNationalId] = useState("");
  const [mobile, setMobile] = useState("");
  const [statusId, setStatusId] = useState(null);
  const [pageSize, setPageSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);

  const gridRef = useRef();

  const { messageBox } = useMessage();

  function mounted() {
    return new Promise((res) => {
      setTimeout(res, 10);
    });
  }

  useEffect(() => {
    mounted().then(() => {
      let size = Math.floor((gridRef.current.grid.clientHeight - 70) / 29);

      setPageSize(size);
    });

    setIsEdit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let tempList = [];

    tempList.push({ menuId: 0, caption: "Search", icon: icons["searchIcon"] });

    if (selectedAgent) {
      tempList.push({ menuId: 1, caption: "Select", icon: icons["selectIcon"] });
    }

    tempList.push({ menuId: 2, caption: "Cancel", icon: icons["cancelIcon"] });

    setMenuList(tempList);
  }, [selectedAgent]);

  const menuClick = (menuId) => {
    switch (menuId) {
      case 0:
        search(1);
        break;
      case 1:
        setAgent(selectedAgent);
        setIsEdit(false);
        onHide();
        break;
      default:
        setIsEdit(false);
        onHide();
        break;
    }
  };

  const search = async (pageIndex) => {
    setIsBusy(true);

    const parameter = { agentData: { firstName: `%${firstName}%`, lastName: `%${lastName}%`, nationalId: `%${nationalId}%`, mobile: `%${mobile}%` }, pageIndex: pageIndex - 1, pageSize: pageSize };

    if (statusId) {
      parameter.agentData.statusId = statusId;
    }

    try {
      await axios
        .post("/agent/searchAgent", JSON.stringify(parameter), {
          headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        })
        .then((response) => {
          setIsBusy(false);

          let result = response.data;

          if (result.type !== 1) {
            messageBox({ type: result.type, message: result.message }).then(() => {
              gridRef.current.grid.focus();
            });
          } else {
            if (result.agentList.length > 0) {
              setResultList(result.agentList);
              setCurrentPage(pageIndex);
              setTotalPage(result.agentList[0].totalRecord % pageSize > 0 ? parseInt(result.agentList[0].totalRecord / pageSize) + 1 : parseInt(result.agentList[0].totalRecord / pageSize));
              setTotalRecord(result.agentList[0].totalRecord);

              setSelectedAgent(result.agentList[0]);
            } else {
              setResultList([]);
              setCurrentPage(0);
              setTotalPage(0);
              setTotalRecord(0);

              setSelectedAgent(null);
            }

            gridRef.current.grid.focus();
          }
        });
    } catch (error) {
      setIsBusy(false);

      messageBox({ type: 99, message: error.message }).then(() => {
        gridRef.current.grid.focus();
      });
    }
  };

  const doubleClick = () => {
    setAgent(selectedAgent);
    setIsEdit(false);
    onHide();
  };

  const navigate = (newPageIndex) => {
    setCurrentPage(newPageIndex);
    search(newPageIndex);
  };

  return (
    <div className="overlay" style={{ zIndex: 97 }}>
      <div className="login-form" style={{ boxShadow: "0 0 10px var(--blurry)", width: "70%", height: "80%" }}>
        <div className="login-title">
          <p>SEARCH AGENT</p>
        </div>
        <Toolbar itemSource={menuList} isChild={true} onClick={(menuId) => menuClick(menuId)} />

        <StackPanel orientation="column">
          <ParameterBox>
            <ParameterElement label="Mobile">
              <TextBox text={mobile} setText={setMobile} placeHolder="Mobile" />
            </ParameterElement>
            <ParameterElement label="ID number">
              <TextBox text={nationalId} setText={setNationalId} placeHolder="ID number" />
            </ParameterElement>
            <ParameterElement label="First name">
              <TextBox text={firstName} setText={setFirstName} placeHolder="First name" />
            </ParameterElement>
            <ParameterElement label="Last name">
              <TextBox text={lastName} setText={setLastName} placeHolder="Last name" />
            </ParameterElement>
            <ParameterElement>
              <ComboBox itemSource={statusList} fields={lookupFields} displayField="lookupContent" selectionField="lookupId" selectedValue={statusId} onSelectionChanged={setStatusId} placeHolder="Status" />
            </ParameterElement>
          </ParameterBox>
          <DataSearchGrid ref={gridRef} itemSource={resultList} isChild={true} fields={fields} currentPage={currentPage} totalPage={totalPage} totalRecord={totalRecord} onSelectItem={setSelectedAgent} onDoubleClick={doubleClick} onNavigate={navigate} units={["agent", "agents"]} />
        </StackPanel>
      </div>
    </div>
  );
};

export default AgentSearch;
