import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useMaster, useMessage } from "../../context/MasterContext";
import { Container, TextBox, PasswordBox } from "../../controls";
import { validateMobile } from "../../library/helper";

import axios from "../../library/service";

const LoginPage = () => {
  const { socket, setIsBusy, setUser, setToken } = useMaster();
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");

  const mobileRef = useRef();
  const passwordRef = useRef();

  const { messageBox } = useMessage();

  const navigate = useNavigate();

  useEffect(() => {
    const storedMobile = localStorage.getItem("mobile");

    if (storedMobile !== null) {
      if (setMobile(storedMobile));
      passwordRef.current.focus();
    } else {
      mobileRef.current.focus();
    }
  }, []);

  const mobileEnter = () => {
    if (mobile) passwordRef.current.focus();
  };

  const passwordEnter = () => {
    if (password) clickLogin();
  };

  const clickLogin = async () => {
    if (validateLogin()) login();
  };

  const clickForgot = () => {};

  const validateLogin = () => {
    if (!mobile) {
      messageBox({ type: 2, message: "Mobile required" }).then(() => {
        mobileRef.current.focus();
      });
      return false;
    }

    if (!validateMobile(mobile)) {
      messageBox({ type: 2, message: "Invalid mobile number" }).then(() => {
        mobileRef.current.focus();
      });
      return false;
    }

    if (!password) {
      messageBox({ type: 2, message: "Password required" }).then(() => {
        passwordRef.current.focus();
      });
      return false;
    }

    return true;
  };

  const login = async () => {
    setIsBusy(true);

    const parameter = { mobile, password, deviceId: "Windows", trusted: false, isApp: false };

    try {
      await axios.post("/signIn", JSON.stringify(parameter)).then((response) => {
        setIsBusy(false);

        let result = response.data;

        if (result.type !== 1) {
          messageBox({ type: result.type, message: result.message }).then(() => {
            passwordRef.current.focus();
          });
        } else {
          localStorage.setItem("mobile", mobile);
          setMobile("");
          setPassword("");

          setUser(result.agent);
          setToken(result.token);

          socket.emit("signIn", {
            agentId: result.agent.agentId,
            firstName: result.agent.firstName,
            lastName: result.agent.lastName,
            mobile: result.agent.mobile,
            isApp: false,
          });

          navigate("/");
        }
      });
    } catch (error) {
      setIsBusy(false);

      messageBox({ type: 99, message: error.message }).then(() => {
        passwordRef.current.focus();
      });
    }
  };

  return (
    <div className="login-main">
      <div className="login-background">
        <img src="" alt="PstBet Logo" />
      </div>
      <div className="login-content">
        <div className="login-form">
          <div className="login-title">
            <p>LOGIN</p>
          </div>
          <Container mode="wrap">
            <p className="login-label">Mobile</p>
            <TextBox ref={mobileRef} text={mobile} setText={setMobile} placeHolder="Enter mobile" pressEnter={mobileEnter} />
            <p className="login-label" style={{ marginTop: "30px" }}>
              Password
            </p>
            <PasswordBox ref={passwordRef} password={password} setPassword={setPassword} placeHolder="Enter password" pressEnter={passwordEnter} />
            <button className="login-button" onClick={clickLogin}>
              Login
            </button>
            <div className="login-forgot" onClick={clickForgot}>
              <p>Forgot password?</p>
            </div>
          </Container>
        </div>
        <div className="login-footer">
          <p>Broadgates Technology Solutions © 2022-{new Date().getFullYear()}</p>
          <p>All rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
