import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useMaster, useMessage } from "../../../context/MasterContext";
import { Container, GroupBox, GroupElement, ComboBox, PasswordBox } from "../../../controls";
import { lookupFields } from "../../../library/static";

import axios from "../../../library/service";

const typeList = [
  { lookupId: 2, lookupContent: "BRONZE AGENT" },
  { lookupId: 3, lookupContent: "SILVER AGENT" },
  { lookupId: 4, lookupContent: "GOLD AGENT" },
  { lookupId: 5, lookupContent: "PLATINUM AGENT" },
  { lookupId: 6, lookupContent: "DIAMOND AGENT" },
];

const AgentChange = ({ agent, setAgent, onHide }) => {
  const { socket, setIsBusy, setUser, token, setIsEdit } = useMaster();
  const [typeId, setTypeId] = useState(agent.typeId);
  const [pinCode, setPinCode] = useState("");
  const [attempt, setAttempt] = useState(0);

  const typeRef = useRef();
  const pinRef = useRef();

  const navigate = useNavigate();

  const { messageBox } = useMessage();

  useEffect(() => {
    setIsEdit(true);
    typeRef.current.focus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeClick = () => {
    if (typeId === null) {
      messageBox({ type: 2, message: "Choose agent type" }).then(() => {
        typeRef.current.focus();
      });
    } else if (typeId === agent.typeId) {
      messageBox({ type: 2, message: "No change has been made" }).then(() => {
        typeRef.current.focus();
      });
    } else if (pinCode === "") {
      messageBox({ type: 2, message: "Enter your PIN code" }).then(() => {
        pinRef.current.focus();
      });
    } else if (pinCode.length !== 4) {
      messageBox({ type: 2, message: "Invalid PIN code, try again" }).then(() => {
        pinRef.current.focus();
      });
    } else if (!/^\d+$/.test(pinCode)) {
      messageBox({ type: 2, message: "Invalid PIN code, try again" }).then(() => {
        pinRef.current.focus();
      });
    } else {
      change();
    }
  };

  const change = async () => {
    setIsBusy(true);

    const parameter = { agentId: agent.agentId, typeId, pinCode, attempt };

    try {
      await axios.post("/agent/changeAgent", JSON.stringify(parameter), { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then((response) => {
        setIsBusy(false);

        let result = response.data;

        if (result.type !== 1) {
          if (result.type === 96) {
            setAttempt(attempt + 1);
            messageBox({ type: result.type, message: `${result.message}\n\n${2 - attempt} ${2 - attempt > 1 ? "ATTEMPTS" : "ATTEMPT"} LEFT!!!` }).then(() => {
              pinRef.current.focus();
            });
          } else if (result.type === 97) {
            messageBox({ type: 99, message: result.message }).then(() => {
              setIsEdit(false);
              setUser(null);
              socket.emit("signOut");
              navigate("/");
            });
          } else {
            messageBox({ type: result.type, message: result.message }).then(() => {
              pinRef.current.focus();
            });
          }
        } else {
          messageBox({ type: result.type, message: result.message }).then(() => {
            const updatedAgent = { ...agent };
            updatedAgent.typeId = typeId;
            setAgent(updatedAgent);
            onHide();
            setIsEdit(false);
          });
        }
      });
    } catch (error) {
      setIsBusy(false);

      messageBox({ type: 99, message: error.message }).then(() => {
        pinRef.current.focus();
      });
    }
  };

  const cancelClick = () => {
    onHide();
    setIsEdit(false);
  };

  return (
    <div className="overlay" style={{ zIndex: 97 }}>
      <div className="login-form" style={{ boxShadow: "0 0 10px var(--blurry)", width: "400px" }}>
        <div className="login-title">
          <p>CHANGE AGENT TYPE</p>
        </div>
        <Container mode="wrap">
          <hr className="separator-top" />
          <p className="detail-info">
            {agent.agentName} - {agent.nationalId}
          </p>
          <p className="detail-info">{agent.mobile}</p>
          <hr className="separator" />
          <p className="detail-description">agent type will be changed. Please complete form below</p>
          <GroupBox type="child">
            <GroupElement label="Agent type">
              <ComboBox ref={typeRef} itemSource={typeList} fields={lookupFields} displayField="lookupContent" selectionField="lookupId" selectedValue={typeId} onSelectionChanged={setTypeId} />
            </GroupElement>
            <GroupElement label="PIN code">
              <PasswordBox ref={pinRef} password={pinCode} setPassword={setPinCode} />
            </GroupElement>
          </GroupBox>
          <p className="caution">CAUTION: IF YOU ENTER INCORRECT PIN 3 TIMES THEN YOUR ACCOUNT WILL BE BLOCKED!!!</p>
          <hr className="separator-bottom" />
          <div className="login-reset">
            <button onClick={changeClick}>Change</button>
            <button onClick={cancelClick}>Cancel</button>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default AgentChange;
