import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

const CurrencyBox = forwardRef(({ type, digits, minValue, maxValue, step, value, setValue }, ref) => {
  const inputRef = useRef();

  function mounted() {
    return new Promise((res) => {
      setTimeout(res, 10);
    });
  }

  useImperativeHandle(ref, () => ({
    focus() {
      mounted().then(() => {
        inputRef.current.focus();
        inputRef.current.select();
      });
    },
  }));

  useEffect(() => {
    let digit = 2;

    if (digits) digit = digits;

    if (value) {
      let num = value.toFixed(digit).split(".");
      inputRef.current.value = `${num[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${num[1]}`;
    } else inputRef.current.value = "";
  }, [value, digits]);

  const gotFocus = (e) => {
    if (e.target.value !== "") {
      var num = e.target.value.split(".");
      if (Number(num[1]) > 0) e.target.value = `${num[0].replace(/,/g, "")}.${Number(num[1])}`;
      else e.target.value = num[0].replace(/,/g, "");
    }

    e.target.select();
  };

  const lostFocus = (e) => {
    switch (e.target.value) {
      case ".":
      case "-":
      case "-.":
      case "":
        e.target.value = "";
        break;
      case "-0":
      case "-0.":
      case ".0":
        e.target.value = "0";
        break;
      default:
        if (e.target.value != null && isNaN(Number(e.target.value))) e.target.value = "";
        break;
    }

    var newValue = null;
    let digit = 2;

    if (digits) digit = digits;

    if (e.target.value !== "") {
      newValue = Number(Number(e.target.value).toFixed(digit));

      if (minValue) {
        if (newValue < minValue || newValue > maxValue) newValue = null;
      } else {
        if (newValue < -999999999999.99 || newValue > 999999999999.99) newValue = null;
      }

      if (minValue && step && newValue != null) {
        let a = newValue - minValue;
        let r = a % step;

        if (r !== 0) newValue = null;
      }
    }

    if (newValue != null) {
      var num = newValue.toFixed(digit).split(".");
      e.target.value = `${num[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${num[1]}`;
    } else e.target.value = "";

    if (setValue) setValue(newValue);
  };

  const valueChange = (e) => {
    let allowNegative = true;

    if (minValue && minValue > 0) allowNegative = false;

    if (allowNegative) {
      let firstCharacter = e.target.value.slice(0, 1);

      if (firstCharacter === "-") {
        var afterMinus = e.target.value.slice(1, e.target.value.length);
        e.target.value = `-${afterMinus.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")}`;
      } else e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    } else e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
  };

  return <input type="text" ref={inputRef} className={type ? "grid-box" : "normal-box"} style={{ textAlign: "right" }} spellCheck="false" autoComplete="false" autoCorrect="false" onFocus={gotFocus} onBlur={lostFocus} onChange={valueChange} />;
});

export default CurrencyBox;
